import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ForbiddenComponent } from './forbidden/forbidden.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/core/core.module').then(m => m.CoreModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
