import { InjectionToken } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { NOOP } from './actions';
import * as fromCore from '../features/core/store/core.reducer';
import * as fromAlerting from '../features/alerting/store/alerting.reducer';
import * as fromForms from '../features/forms/store/forms.reducer';
import * as fromDashboard from '../features/dashboard/store/dashboard.reducer';
import * as fromSearch from '../features/search/store/search.reducer';
import * as fromExplorer from '../features/explorer/store/explorer.reducer';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { AlertingActions } from '../features/alerting/store';
import { SearchActions } from '../features/search/store';
// import { CoreActions } from '../features/core/store';
import { environment } from 'src/environments/environment';
import { CoreActions } from '../features/core/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
    router: RouterReducerState,
    [fromCore.coreFeatureKey]: fromCore.State,
    [fromAlerting.alertingFeatureKey]: fromAlerting.State,
    [fromForms.formsFeatureKey]: fromForms.State,
    [fromSearch.searchFeatureKey]: fromSearch.State,
    [fromExplorer.explorerFeatureKey]: fromExplorer.State,
    [fromDashboard.dashboardFeatureKey]: fromDashboard.State
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('RootState', {
    factory: (): ActionReducerMap<State, Action> => ({
        router: routerReducer,
        [fromCore.coreFeatureKey]: fromCore.reducer,
        [fromAlerting.alertingFeatureKey]: fromAlerting.reducer,
        [fromForms.formsFeatureKey]: fromForms.reducer,
        [fromDashboard.dashboardFeatureKey]: fromDashboard.reducer,
        [fromSearch.searchFeatureKey]: fromSearch.reducer,
        [fromExplorer.explorerFeatureKey]: fromExplorer.reducer
    })
});

const actionsNoLog: readonly string[] = [
    NOOP.type,
    AlertingActions.getNextHistoryDispatcher.type,
    SearchActions.getNextMetrics.type,
    CoreActions.showSpinner.type,
    CoreActions.hideSpinner.type
];

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const result = reducer(state, action);
        if (!action.type.startsWith('@ngrx/router-store') && !actionsNoLog.includes(action.type)) {
            console.groupCollapsed(action.type);
            console.log('prev state', state);
            console.log('action', action);
            console.log('next state', result);
            console.groupEnd();
        }
        return result;
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = environment.env !== 'prd' ? [logger] : [];
