import { NEVER, combineLatestWith, first, switchMap } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable,inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CoreActions, fromCore } from 'src/app/features/core/store';

@Injectable({
    providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {

    private store = inject(Store);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {        
        return this.store.select(fromCore.selectIsOffline)
            .pipe(
                combineLatestWith(
                    this.store.select(fromCore.selectShowSpinner)
                ),
                first(),
                switchMap(([isOffline, spinnerVisible]) => { 
                    if(request.url.startsWith('./') || !isOffline)
                        return next.handle(request);

                    if(spinnerVisible)     
                        this.store.dispatch(CoreActions.hideSpinner());
                            
                    return NEVER;
                })
            );
    }
}
