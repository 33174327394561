import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { I18nTitleStrategy } from './shared/services/i18n-title-strategy';
import { ROOT_REDUCERS, metaReducers } from './store';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { RequestInterceptor } from './shared/interceptors/request.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        ForbiddenComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot([], { bindToComponentInputs: true }),
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: i18nLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        AuthModule.forRoot({
            domain: environment.auth0Domain,
            clientId: environment.oauth2ClientID,
            authorizationParams: {
                audience: environment.auth0Audience,
                redirect_uri: window.location.origin,
                scope: `${environment.scope} profile email`
            },
            errorPath: '/forbidden',
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
            useRefreshTokensFallback: true,
            useFormData: false,
            httpInterceptor: {
                allowedList: [
                    `${environment.indabaBaseUrl}*`,
                    `${environment.indametaBaseUrl}*`,
                    `${environment.umsBaseUrl}*`,
                    `${environment.adminBaseUrl}*`,
                    `${environment.alertingBaseUrl}*`
                ]
            }
        }),
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers,
            runtimeChecks: {
                strictStateSerializability: true,
                strictActionImmutability: true,
                strictActionTypeUniqueness: true
            }
        }),
        EffectsModule.forRoot(),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            name: `iobase-mobile-${environment.env}`,
            maxAge: 25,
            actionsBlocklist: ['NOOP']
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center'
        })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: redirectToCorrectWebsite,
            deps: [DeviceDetectorService],
            multi: true
        },
        {
            provide: TitleStrategy,
            useClass: I18nTitleStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function i18nLoaderFactory(http: HttpBackend): TranslateHttpLoader {
    return new TranslateHttpLoader(new HttpClient(http), './assets/i18n/', '.json');
}

export function redirectToCorrectWebsite(device: DeviceDetectorService): () => Promise<void> {
    return () => new Promise((resolve) => {
        if (!device.isMobile() && !device.isTablet())
            location.href = environment.desktopUrl;
        resolve();
    });
}
